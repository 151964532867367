<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>NASCC北美赛营地</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="赛季" prop="season">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.season"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_season"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="所属赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.districtId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="studentPhone">
                            <el-input
                                v-model="queryForm.studentPhone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="具备美签" prop="type">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.visa"
                                clearable
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="审核状态" prop="informationReview">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.informationReview"
                                clearable
                            >
                                <el-option
                                    label="待审核"
                                    :value="-1"
                                ></el-option>
                                <el-option
                                    label="通过"
                                    :value="1"
                                ></el-option>
                                <el-option
                                    label="驳回"
                                    :value="0"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否面签" prop="interview">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.interview"
                                clearable
                            >
                                <el-option label="面签" value="interview"></el-option>
                                <el-option label="非面签" value="not_interview"></el-option>
                                <el-option label="具备签证" value="have_visa"></el-option>
                                <el-option label="未设置" value="not_set"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否缴费" prop="userName">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.payed"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="合同签署" prop="contractSigning">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.contractSigning"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        size="small"
                        style="width: 100%"
                        @click="search"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="warning"
                        icon="el-icon-refresh-right"
                        size="small"
                        style="width: 100%"
                        @click="queryReset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span class="title_class">NASCC报名信息列表</span>
                </el-col>
                <el-col :span="12">
                    <div style="text-align: right">
                        <el-button
                            type="primary"
                            size="small"
                            @click="campUserExport"
                            icon="el-icon-download"
                            >导出报名信息</el-button
                        >
                        <el-button
                            type="success"
                            size="small"
                            @click="importFn"
                            icon="el-icon-upload2"
                            >导入报名信息</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                :data="campUserList"
                header-cell-class-name="tableHeaderStyle"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column
                    label="报名时间"
                    prop="createTime"
                ></el-table-column>
                <el-table-column label="选手信息" prop="userName">
                    <template slot-scope="scope">
                        <div>
                            <span style="font-weight: 700;">{{ scope.row.userName }} {{ scope.row.sex === '1' ? '(男)' : '(女)' }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否注册小程序"
                    prop="userId"
                >
                    <template slot-scope="scope">
                        <el-tag size="mini" :type="scope.row.userId ? 'success' : 'info'">{{ scope.row.userId ? '已注册' : '未注册' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="手机号"
                    prop="studentPhone"
                ></el-table-column>
                <el-table-column
                    label="所属赛区"
                    prop="districtName"
                ></el-table-column>
                <el-table-column
                    label="学校名称"
                    prop="studySchool"
                ></el-table-column>
                <el-table-column
                    label="年级"
                    prop="grade"
                    :formatter="gradeFormatter"
                ></el-table-column>
                <el-table-column label="是否具备美签" prop="visa">
                    <template slot-scope="scope">
                        {{ scope.row.visa ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column label="审核状态" prop="informationReview">
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.informationReview === -1 ? '' : scope.row.informationReview === 0 ? 'danger' : scope.row.informationReview === 1 ? 'success' : ''"
                            size="mini"
                            >{{ scope.row.informationReview === -1 ? '待审核' : scope.row.informationReview === 0 ? '驳回' : scope.row.informationReview === 1 ? '通过' : '' }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="是否面签" prop="interview">
                    <template slot-scope="scope">
                        {{ scope.row.interview === 'interview' ? '面签' : scope.row.interview === 'not_interview' ? '非面签': scope.row.interview === 'have_visa' ? '具备签证' : '未设置' }}
                    </template>
                </el-table-column>
                <el-table-column label="是否缴费" prop="payed">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.payed === true"
                            size="mini"
                            >已缴费</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未缴费</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="合同签署" prop="contractSigning">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.contractSigning"
                            size="mini"
                            >已签署</el-tag
                        >
                        <el-tag type="warning" v-else size="mini">未签署</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="美签材料" prop="visaStstus" width="120px">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.visaStstus === 0 ? 'info' :
                            scope.row.visaStstus === 1 ? 'warning' :
                            scope.row.visaStstus === 2 ? '' :
                            scope.row.visaStstus === 3 ? 'danger' :
                            scope.row.visaStstus === 4 ? 'success' : ''" size="mini">{{ scope.row.visaStstus === 0 ? '未提交' :
                            scope.row.visaStstus === 1 ? '已提交' :
                            scope.row.visaStstus === 2 ? '已接收' :
                            scope.row.visaStstus === 3 ? '已退还' :
                            scope.row.visaStstus === 4 ? '已完成' : '' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="240px" fixed="right">
                    <template slot-scope="scope">
                        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
                            <div style="display: flex; margin-bottom: 8px;">
                                <el-button
                                    type="text"
                                    size="small"
                                    style="margin-right: 10px;"
                                    @click="handleToDetail(scope.row, true)"
                                    >报名审核</el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    style="margin-right: 10px;"
                                    @click="payedFn(scope.row)"
                                    >缴费信息</el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="contractFn(scope.row)"
                                    >合同管理</el-button>
                            </div>
                            <div style="display: flex;">
                                <el-button
                                    type="text"
                                    size="small"
                                    style="margin-right: 10px;"
                                    @click="handleToDetail(scope.row, false)"
                                    >设置面签</el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    style="margin-right: 10px;"
                                    :disabled="scope.row.visaStstus === 0"
                                    @click="handleToVisa(scope.row)"
                                    >美签申请材料</el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="handleToLog(scope.row)"
                                    >操作日志</el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                v-if="campUserList.length !== 0"
            >
            </el-pagination>
        </el-card>
        <el-dialog
            title="缴费信息"
            :visible.sync="addInfoDialog"
            width="50%"
        >
            <el-form
                :model="form"
                label-width="100px"
            >
                <el-row :gutter="5">
                    <el-col :span="24">
                        <el-form-item label="是否缴费" prop="payed">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="form.payed"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注" prop="payedNote">
                            <el-input
                                v-model="form.payedNote"
                                type="textarea"
                                rows="4"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="缴费凭证" prop="payedUrl">
                            <!-- <el-upload
                                class="avatar-uploader"
                                :action="uploadImageResults.uploadUrl"
                                :show-file-list="false"
                                :on-success="uploadCoverImgSuccess"
                                :headers="uploadImageResults.headerObj"
                                :data="{
                                    filePath:
                                        'spbcnapp/camp/nascc/paymentVoucherImg/',
                                }"
                            >
                                <img
                                    v-if="form.payedUrl"
                                    class="img-cover"
                                    :src="form.payedUrl"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload> -->
                            <div>
                                <div class="img" v-for="(url, i) in form.payedUrl" :key="i">
                                    <img :src="url" alt="">
                                    <div class="cover" @click="delImg(i)"><i class="el-icon-delete"></i></div>
                                </div>
                            </div>
                            <el-upload
                                :action="uploadImageResults.uploadUrl"
                                :show-file-list="false"
                                :on-success="uploadCoverImgSuccess"
                                :headers="uploadImageResults.headerObj"
                                :data="{
                                    filePath:
                                        'spbcnapp/camp/nascc/paymentVoucherImg/',
                                }">
                                <el-button type="primary">选择图片</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveFn2"
                    >确认</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="合同管理"
            :visible.sync="addInfo2Dialog"
            width="50%"
        >
            <el-form
                :model="form"
                label-width="100px"
            >
                <el-row :gutter="5">
                    <el-col :span="24">
                        <el-form-item label="是否签署" prop="contractSigning">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="form2.contractSigning"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上传合同" prop="contractSigningUrl">
                            <el-upload
                                :action="uploadImageResults.uploadUrl"
                                :show-file-list="false"
                                :on-success="uploadFileSuccess"
                                :on-progress="uploadProgress"
                                :headers="uploadImageResults.headerObj"
                                :data="{
                                    filePath:
                                        'spbcnapp/camp/nascc/file/',
                                }"
                            >
                                <el-button size="small" :loading="uploadLoading" type="primary">{{uploadLoading ? '上传中' : (form2.contractSigningUrl ? '重新上传' : '选取文件')}}</el-button>
                                <span style="font-size: 12px;color: #666;margin-left: 10px;">建议上传PDF文件</span>
                            </el-upload>
                            <div class="filename" @click="downLoadFile">{{ form2.contractSigningUrl }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="contractSigningFn"
                    >保存</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="报名信息"
            :visible.sync="infoVisible"
            width="900px">
            <div class="">
                <div class="info-title">学生基本情况</div>
                <el-row>
                    <el-col :span="8" class="col">
                        <div class="label">中文名字</div>
                        <div class="value">{{ userInfo.userName }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">性别</div>
                        <div class="value">{{ userInfo.sex === 0 ? '女' : '男' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">年龄</div>
                        <div class="value">{{ userInfo.age }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">英文名字</div>
                        <div class="value">{{ userInfo.enName }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">出生日期</div>
                        <div class="value">{{ userInfo.birthDate }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">年级</div>
                        <div class="value">{{ formatGrade(userInfo.grade) }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">国籍</div>
                        <div class="value">{{ userInfo.nationality }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">就读学校</div>
                        <div class="value">{{ userInfo.studySchool }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">是否具备美签</div>
                        <div class="value">{{ userInfo.visa ? '是' : '否' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">{{userInfo.cardType === 1 ? '护照' : '身份证'}}号</div>
                        <div class="value">{{ userInfo.idCard || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">电子邮件</div>
                        <div class="value">{{ userInfo.email }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">联系电话</div>
                        <div class="value">{{ userInfo.studentPhone }}</div>
                    </el-col>
                    <el-col :span="24" class="col">
                        <div class="label">参赛理由</div>
                        <div class="value">{{ userInfo.reason }}</div>
                    </el-col>
                </el-row>
                <div class="info-title">选手目前参加过最高级别的Spelling Bee比赛</div>
                <el-row>
                    <el-col :span="8" class="col">
                        <div class="label">比赛级别</div>
                        <div class="value">{{ userInfo.spbcnMatchLevel || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">参加赛季</div>
                        <div class="value">{{ userInfo.spbcnMatchDate || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">参赛名次</div>
                        <div class="value">{{ userInfo.spbcnMatchRanking || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">奖金</div>
                        <div class="value">{{ userInfo.spbcnMatchBonus || '--' }}</div>
                    </el-col>
                </el-row>
                <div class="info-title">家庭成员情况</div>
                <el-row>
                    <el-col :span="8" class="col">
                        <div class="label">父亲姓名</div>
                        <div class="value">{{ userInfo.fatherName || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">父亲年龄</div>
                        <div class="value">{{ userInfo.fatherAge || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">父亲联系电话</div>
                        <div class="value">{{ userInfo.fatherPhone || '--' }}</div>
                    </el-col>
                    <el-col :span="24" class="col">
                        <div class="label">父亲工作单位及职务</div>
                        <div class="value">{{ userInfo.fatherPosition || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">母亲姓名</div>
                        <div class="value">{{ userInfo.motherName || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">母亲年龄</div>
                        <div class="value">{{ userInfo.motherAge || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">母亲联系电话</div>
                        <div class="value">{{ userInfo.motherPhone || '--' }}</div>
                    </el-col>
                    <el-col :span="24" class="col">
                        <div class="label">母亲工作单位及职务</div>
                        <div class="value">{{ userInfo.motherPosition || '--' }}</div>
                    </el-col>
                    <el-col :span="8" class="col">
                        <div class="label">邮政编码</div>
                        <div class="value">{{ userInfo.postalCode || '--' }}</div>
                    </el-col>
                    <el-col :span="16" class="col">
                        <div class="label">家庭住址</div>
                        <div class="value">{{ userInfo.homeAddress || '--' }}</div>
                    </el-col>
                </el-row>
                <div v-if="!isAudit">
                    <el-divider></el-divider>
                    <div class="col col2">
                        <div class="label">是否面签</div>
                        <div>
                            <el-radio-group v-model="interview">
                                <el-radio label="interview">面签</el-radio>
                                <el-radio label="not_interview">非面签</el-radio>
                                <el-radio label="have_visa">具备签证</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <el-button type="primary" class="btn" v-if="userInfo.informationReview !== -1" @click="interviewFn">提 交</el-button>
                </div>
                <div v-else>
                    <el-divider></el-divider>
                    <div class="col col2">
                        <div class="label">审核状态</div>
                        <div>
                            <el-radio-group v-model="informationReview" :disabled="userInfo.informationReview !== -1">
                                <el-radio :label="1">通过</el-radio>
                                <el-radio :label="0">驳回</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="col col2" v-if="informationReview === 0">
                        <div class="label">驳回原因</div>
                        <div>
                            <el-input style="width: 400px;" type="textarea" :disabled="userInfo.informationReview !== -1" :rows="4" placeholder="请输入内容" v-model="informationReviewNote"></el-input>
                        </div>
                    </div>
                    <el-button type="primary" class="btn" v-if="userInfo.informationReview === -1" @click="saveFn">提 交</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            title="操作日志"
            :visible.sync="logDialog"
            width="800px"
        >
            <el-button type="primary" plain size="small" @click="showNote">添加备注</el-button>
            <el-table
                :data="logList"
                header-cell-class-name="tableHeaderStyle"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="操作者" prop="name" width="120px"></el-table-column>
                <el-table-column label="操作" prop="content" width="300px"></el-table-column>
                <el-table-column label="操作时间" prop="createTime"></el-table-column>
                <el-table-column label="终端" prop="terminal" :formatter="terminalFormat"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
            title="添加备注"
            :visible.sync="noteVisible"
            width="500px">
            <el-input type="textarea" :rows="4" v-model="content"></el-input>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveNote">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="美国签证申请材料接收清单"
            :visible.sync="visaDialog"
            width="800px"
        >
            当前进度：
            <el-tag :type="visaStstus === 0 ? 'info' :
                visaStstus === 1 ? 'warning' :
                visaStstus === 2 ? '' :
                visaStstus === 3 ? 'danger' :
                visaStstus === 4 ? 'success' : ''">{{ visaStstus === 0 ? '未提交' :
                visaStstus === 1 ? '已提交' :
                visaStstus === 2 ? '已接收' :
                visaStstus === 3 ? '已退还' :
                visaStstus === 4 ? '已完成' : '' }}</el-tag>
            <el-table
                :data="visaList"
                header-cell-class-name="tableHeaderStyle"
                border
                stripe
            >
                <el-table-column label="序号" prop="sort" width="50px"></el-table-column>
                <el-table-column label="材料名称" prop="name"></el-table-column>
                <el-table-column label="提交数量" prop="num">
                    <template slot-scope="scope">
                        <el-input-number v-if="visaStstus === 1" size="small" v-model="scope.row.num" ></el-input-number>
                        <div v-else>{{ scope.row.num }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="选手已提交" prop="submitType" width="140px">
                    <template slot-scope="scope">
                        <el-tag style="margin-right: 10px;" type="" size="small" v-if="scope.row.submitType === 'original' || scope.row.submitType === 'all'">原件</el-tag>
                        <el-tag type="success" size="small" v-if="scope.row.submitType === 'photocopy' || scope.row.submitType === 'all'">复印件</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="确认接收" v-if="visaStstus === 1" width="180px">
                    <template slot-scope="scope">
                        <!-- <el-checkbox-group v-model="scope.row.receive" v-if="visaStstus === 1"> -->
                            <el-checkbox label="原件" v-model="scope.row.receiveOriginal"></el-checkbox>
                            <el-checkbox label="复印件" v-model="scope.row.receivePhotocopy"></el-checkbox>
                        <!-- </el-checkbox-group> -->
                    </template>
                </el-table-column>
                <el-table-column label="已接收文件" v-if="visaStstus > 1" width="140px">
                    <template slot-scope="scope">
                        <el-tag style="margin-right: 10px;" type="" size="small" v-if="scope.row.receiveType === 'original' || scope.row.receiveType === 'all'">原件</el-tag>
                        <el-tag type="success" size="small" v-if="scope.row.receiveType === 'photocopy' || scope.row.receiveType === 'all'">复印件</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="退还文件" v-if="visaStstus === 2" width="180px">
                    <template slot-scope="scope">
                        <el-checkbox label="原件" v-model="scope.row.returnOriginal"></el-checkbox>
                        <el-checkbox label="复印件" v-model="scope.row.returnPhotocopy"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="已退还文件" v-if="visaStstus > 2" width="140px">
                    <template slot-scope="scope">
                        <el-tag style="margin-right: 10px;" type="" size="small" v-if="scope.row.returnType === 'original' || scope.row.returnType === 'all'">原件</el-tag>
                        <el-tag type="success" size="small" v-if="scope.row.returnType === 'photocopy' || scope.row.returnType === 'all'">复印件</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin-top: 20px;" v-if="visaStstus === 1 || visaStstus === 2">
                <el-button type="primary" @click="submitFn">提 交</el-button>
            </div>
        </el-dialog>
        <el-dialog
            width="500px"
            title="批量导入报名信息"
            :visible="importVisible"
            @close="handleClose"
        >
            <el-form label-width="140px" ref="importFormRef">
                <el-form-item label="上传报名信息Excel" prop="districtid">
                    <el-upload
                        ref="uploadFileRef"
                        :action="uploadFileRequest.uploadUrl"
                        :headers="uploadFileRequest.headerObj"
                        :before-upload="beforeUpload"
                        :limit="1"
                        :on-success="uploadSuccess"
                        :on-progress="uploadProgress"
                        :on-error="uploadError"
                    >
                        <el-button
                            class="mr-10"
                            type="primary"
                            :loading="uploadLoading"
                            >{{ uploadLoading ? '上传中' : '选择文件'}}</el-button
                        >
                    </el-upload>
                    <el-link type="primary" @click="downLoadFn">下载模版</el-link>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { nasccList, queryDistrictPage, nasccInfo, nasccReview, nasccPayed, nasccInterview, nasccContractSigning, getVisaList, updateReceiveType, updateReturnType, getTrackLog, addTrackLog } from '@/http/api'

export default {
  data () {
    return {
      uploadImageResults: {
        uploadUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      dict_grade: this.$userInfo.getGradeData(),
      dict_district: [],
      addInfoDialog: false,
      boolData: this.$userInfo.dict_boolData(),
      dict_campType: this.$userInfo.getDataList(this, 't_camp_type').then(value => { this.dict_campType = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        pageNum: 1,
        pageSize: 10,
        userName: '',
        studentPhone: '',
        payed: '',
        visa: '',
        informationReview: '',
        districtId: '',
        interview: '',
        contractSigning: ''
      },
      total: 0,
      campUserList: [],
      infoVisible: false,
      userInfo: {},
      informationReview: 1,
      informationReviewNote: '',
      form: {
        id: '',
        payed: '',
        payedNote: '',
        payedUrl: []
      },
      interview: 'interview',
      addInfo2Dialog: false,
      form2: {
        id: '',
        contractSigning: true,
        contractSigningUrl: ''
      },
      uploadLoading: false,
      isAudit: false, // 是否审核
      visaDialog: false,
      visaList: [],
      id: '',
      visaStstus: 0,
      logDialog: false,
      logList: [],
      importVisible: false,
      uploadFileRequest: {
        upLoadType: 'file',
        uploadUrl: this.$env.baseIP + 'nasccRegister/importExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      content: '',
      noteVisible: false,
      nasccRegisterId: ''
    }
  },
  created () {
    this.getDistrictList()
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    terminalFormat (data) {
      return data.terminal === 'wechat' ? '蜂拥教育小程序' : data.terminal === 'erp' ? 'ERP' : ''
    },
    gradeFormatter (data) {
      return data.grade ? this.dict_grade.find(item => item.dictValue === Number(data.grade)).dictLabel : ''
    },
    formatGrade (grade) {
      return grade ? this.dict_grade.find(item => item.dictValue === Number(grade)).dictLabel : ''
    },
    getList () {
      console.log('queryForm', this.queryForm)
      nasccList(this.queryForm).then((res) => {
        this.campUserList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    search () {
      this.queryForm.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getList()
    },
    queryReset () {
      this.queryForm = {
        season: this.$chnEngStatusCode.defaultSeason,
        pageNum: 1,
        pageSize: 10,
        userName: '',
        studentPhone: '',
        payed: '',
        visa: '',
        informationReview: ''
      }
      this.getList()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    uploadCoverImgSuccess (res) {
      console.log('res', res)
      this.form.payedUrl.push(res.data)
    //   this.$set(this.form, 'payedUrl', res.data)
    },
    uploadProgress (res) {
      console.log('res1', res)
      this.uploadLoading = true
    },
    uploadFileSuccess (res) {
      console.log('res2', res)
      this.uploadLoading = false
      this.$set(this.form2, 'contractSigningUrl', res.data)
      this.$set(this.form2, 'contractSigning', true)
    },
    // 导出营员
    campUserExport () {
      var downLoadUrl = this.$http.defaults.baseURL + 'nasccRegister/exportExcel'
      var formData = JSON.parse(JSON.stringify(this.queryForm))
      formData.pageSize = 1000
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', formData)
    },
    handleToDetail (item, isAudit) {
      this.isAudit = isAudit
      nasccInfo({ id: item.id }).then(res => {
        if (res.code === 200) {
          this.infoVisible = true
          this.userInfo = res.data
          this.id = item.id
          this.interview = res.data.interview || 'interview'
          this.informationReview = res.data.informationReview === -1 ? 1 : res.data.informationReview
          this.informationReviewNote = res.data.informationReviewNote
        }
      })
    },
    saveFn () {
      nasccReview({
        id: this.id,
        informationReview: this.informationReview,
        informationReviewNote: this.informationReviewNote
      }).then(res => {
        if (res.code === 200) {
          this.infoVisible = false
          this.getList()
        }
      })
    },
    payedFn (item) {
      this.form.id = item.id
      this.form.payed = item.payed
      this.form.payedNote = item.payedNote
      this.form.payedUrl = item.payedUrl ? JSON.parse(item.payedUrl) : []
      this.addInfoDialog = true
    },
    contractFn (item) {
      this.form2.id = item.id
      this.form2.contractSigning = item.contractSigning
      this.form2.contractSigningUrl = item.contractSigningUrl
      this.addInfo2Dialog = true
    },
    delImg (index) {
      this.form.payedUrl.splice(index, 1)
    },
    saveFn2 () {
      nasccPayed({
        id: this.form.id,
        payed: this.form.payed,
        payedNote: this.form.payedNote,
        payedUrl: JSON.stringify(this.form.payedUrl)
      }).then(res => {
        if (res.code === 200) {
          this.addInfoDialog = false
          this.getList()
        }
      })
    },
    interviewFn () {
      nasccInterview({
        id: this.id,
        interview: this.interview
      }).then(res => {
        if (res.code === 200) {
          this.infoVisible = false
          this.getList()
        }
      })
    },
    contractSigningFn () {
      if (!this.form2.contractSigningUrl) return this.$message.warning('合同未上传或上传失败，请重新上传')
      nasccContractSigning(this.form2).then(res => {
        if (res.code === 200) {
          this.addInfo2Dialog = false
          this.getList()
        }
      })
    },
    downLoadFile () {
      window.open(this.form2.contractSigningUrl)
    },
    handleToVisa (item) {
      this.id = item.id
      this.visaStstus = item.visaStstus
      getVisaList({ id: item.id }).then(res => {
        if (res.code === 200) {
          if (this.visaStstus === 0 || this.visaStstus === 1) {
            res.data.forEach(item => {
            //   item.receive = []
              item.receiveOriginal = false
              item.receivePhotocopy = false
            })
            this.visaList = res.data
          } else {
            // 退回只显示上一步组委会勾选的
            res.data.forEach(item => {
              item.returnOriginal = false
              item.returnPhotocopy = false
            })
            this.visaList = res.data.filter(item => item.receiveType)
          }
          setTimeout(() => {
            this.visaDialog = true
          }, 200)
          console.log(this.visaList)
        }
      })
    },
    handleToLog (item) {
      this.nasccRegisterId = item.id
      this.getLogList()
    },
    getLogList () {
      getTrackLog({ id: this.nasccRegisterId }).then(res => {
        if (res.code === 200) {
          this.logDialog = true
          this.logList = res.data
        }
      })
    },
    showNote () {
      this.noteVisible = true
      this.content = ''
    },
    saveNote () {
      addTrackLog({
        nasccRegisterId: this.nasccRegisterId,
        content: this.content
      }).then(res => {
        if (res.code === 200) {
          this.noteVisible = false
          this.getLogList()
        }
      })
    },
    submitFn () {
      if (this.visaStstus === 1) {
        this.receiveFn()
      } else if (this.visaStstus === 2) {
        this.returnFn()
      }
    },
    // 确认接收
    receiveFn () {
      const nasccRegisterVisaList = this.visaList.map(item => {
        return {
          id: item.id,
          num: item.num,
          receiveType: item.receiveOriginal && item.receivePhotocopy ? 'all' : !item.receiveOriginal && !item.receivePhotocopy ? '' : item.receiveOriginal ? 'original' : item.receivePhotocopy ? 'photocopy' : ''
        }
      })
      const form = {
        id: this.id,
        nasccRegisterVisaList
      }
      updateReceiveType(form).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功')
          this.visaDialog = false
          this.getList()
        }
      })
    },
    // 确认退回
    returnFn () {
      const nasccRegisterVisaList = this.visaList.map(item => {
        return {
          id: item.id,
          num: item.num,
          returnType: item.returnOriginal && item.returnPhotocopy ? 'all' : !item.returnOriginal && !item.returnPhotocopy ? '' : item.returnOriginal ? 'original' : item.returnPhotocopy ? 'photocopy' : ''
        }
      })
      const form = {
        id: this.id,
        nasccRegisterVisaList
      }
      updateReturnType(form).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功')
          this.visaDialog = false
          this.getList()
        }
      })
    },
    importFn () {
      this.importVisible = true
    },
    beforeUpload (file) {
    //   if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //     this.$message.error('请选择excel文件上传')
    //     return false
    //   }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.$message.success('文件上传成功')
        this.uploadLoading = false
        this.search()
      } else {
        this.uploadLoading = false
        this.$message.error(res.message)
        this.$refs.uploadFileRef.clearFiles()// 清除文件
      }
    },
    uploadError (error) {
      console.log('uploadError', error)
    },
    downLoadFn () {
      var downLoadUrl = this.$env.baseIP + 'nasccRegister/downloadExcel'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', {})
    },
    handleClose () {
      this.importVisible = false
      this.uploadLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.button-right {
    margin-left: 20px;
}
::v-deep .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

::v-deep .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.img-cover {
    width: 120px;
    display: block;
}

.info-title{
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin-bottom: 14px;
    margin-top: 10px;
    border-bottom: 2px solid #409eff;
    padding-bottom: 2px;
    padding-right: 20px;
    display: inline-block;
}
.col{
    display: flex;
    margin-bottom: 10px;
    .label{
        width: 140px;
        text-align: right;
        padding-right: 10px;
        color: #000;
    }
    .value{
        flex: 1;
    }
}
.col2{
    margin-top: 20px;
}
.btn{
    display: block;
    margin: 20px auto 0;
}
.filename{
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    &:hover{
        color: #4096ff;
        text-decoration: underline;
    }
}
.mr-10{
    margin-right: 10px;
}
.img{
    width: 146px;
    height: 146px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    text-align: center;
    img{
        width: auto;
        height: 146px;
    }
    .cover{
        width: 146px;
        height: 146px;
        display: none;
        background-color: rgba(0,0,0,.5);
        color: #FFF;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        line-height: 146px;
        cursor: pointer;
        i{
            font-size: 24px;
        }
    }
    &:hover{
        .cover{
            display: block;
        }
    }
}
</style>
